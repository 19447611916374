<template>
<div>   
	  <XModal v-if="popupData && popupData.line" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
	  <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
		  <div class='appHead' :style="(popupData.mode==='copy')?'background-color: #afa;':'background-color: #ffa;'">
	             <div style='display: inline-flex; float: left; font-weight: bold; padding-top: 2pt; margin-right: 15pt;'>
	                <div :style="'padding: 3pt; color: '+getFGColor('#000','#008')">
	            	  {{isCopy()?'Copy':'Create'}} {{tr('Placement')}} for {{popupData.line.name}}
	                </div>
	             </div>
	            
	              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
	              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
					  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
					  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
					</svg>
				  </span>
	              </button>
	            </div> <!-- appHead -->
	         
	        <div class="modal-body" id='main-body'>
		      <div :style='getTableStyle()' >
	          <slot name="body">
	           
	            <div class='mainContent' >
	               <table fixed width='100%'>
	               <tr><td width="14%" class="wTitle" :style="getTitleStyle()">Name</td>
	               <td colspan="3"><InputText v-model='placement.name'/></td></tr>
	               <tr v-if="isCopyToDiffentCampaign()">
					   <td class="wTitle" :style="getTitleStyle()">{{tr('Product')}}</td>
					   <td colspan="3"><GSelectSimple :options="allProducts" v-model="productId" entity='Product'/></td>
				   </tr>
				   <tr v-if="isCopyToDiffentCampaign()">
					   <td class="wTitle" :style="getTitleStyle()">{{tr('Campaign')}}</td>
					   <td colspan="3"><GSelectSimple :disabled="!allCampaigns || allCampaigns.length===1" @change='getNewName' :options="allCampaigns" v-model="campaignId" entity='Campaign'/></td>
				   </tr>	   
	               
	               
	               
	               <tr><td class="wTitle" :style="getTitleStyle()">From/To</td><td  colspan="3">
	               <div style='display: inline-flex; width: 45%'>
	               <InputDatePick2 @change='getNewName' :auto='false' v-model='placement.fromDate'/>
	               </div><div style='display: inline-flex; width: 10%'/>
	               <div style='display: inline-flex; width: 45%'>
	               <InputDatePick2 @change='getNewName' :auto='false' v-model='placement.untilDate'/>
	               </div>
	               </td></tr>
					
	               <tr><td class="wTitle" :style="getTitleStyle()">Reference</td>
	               <td  colspan="3"><InputText v-model='placement.reference'/></td></tr>
	               <tr><td class="wTitle" :style="getTitleStyle()">{{tr("BusinessType")}}</td>
	               <td colspan="3"><GSelectMSimple @change='getNewName' :options="businessTypes" v-model="placement.businessTypeIds" entity='BusinessType'/></td></tr>
	               <tr>
	               <td class="wTitle" :style="getTitleStyle()">{{tr("PlacementType")}}</td>
	               <td width="35%"><GSelectSimple @change='getNewName' :options="placementTypes" v-model="placement.typeId" entity='PlacementType'/></td>
	               
	               
	               <td width="12%" class="wTitle" :style="getTitleStyle()">{{tr("PlacementStatus")}}</td>
	               <td width="35%"><GSelectSimple :options="statuses" v-model="placement.statusId" entity='PlacementStatus'/></td></tr>
	               <tr><td class="wTitle" :style="getTitleStyle()">Target</td><td colspan="3">
	               <div style='display: inline-flex; width: 30%'>
	               	<InputMoney v-model='placement.target'/>
	               </div>
	               <div style='display: inline-flex; width: 70%'>
	               <GSelectSimple :options="targetTypes" v-model="placement.targetTypeId" entity='TargetType'/>
	               </div>
	               </td></tr>
	               <tr ><td class="wTitle" :style="getTitleStyle()">{{tr("Currency")}}</td><td><GSelectSimple @change='getNewName' :options="currencies" v-model="placement.currencyId" entity='Currency'/></td>
	               <td class="wTitle" :style="getTitleStyle()">{{tr("SalesUnit")}}</td><td><GSelectSimple @change='getNewName' :options="salesUnits" v-model="placement.salesUnitId" entity='SalesUnit'/></td></tr>
	               
	               <tr><td class="wTitle" :style="getTitleStyle()">Price</td><td colspan="3">
	               <div style='display: inline-flex; width: 30%'>
	               	<InputMoney :currencyObj="findCurrency()" v-model='placement.price'/>
	               </div>
	               <div style='display: inline-flex; width: 70%'>
	               <GSelectSimple :options="priceTypes" v-model="placement.priceTypeId" entity='PriceType'/>
	               </div>
	               </td></tr>
	
	               <tr>
	                 <td class="wTitle" :style="getTitleStyle()">{{tr("Inventory")}}</td>
	                 <td colspan="3">
	                 <InventoryFilterEditor  
		                 	  	v-model="placement.inventory"
		                 	  	:columnName="'inventory'"
		                 	  	:parentRecord="placement"
		                 	  	@open="openInvEditor"
		                 	  />
	                 </td>
	                  
	               </tr>
	               
	               <tr v-if="timeLayers.length > 1"><td class="wTitle" :style="getTitleStyle()">{{tr("Grid time layer")}}</td><td colspan="3"><GSelectSimple @change='getNewName' :options="timeLayers" v-model="placement.timeLayerId" entity='GridTimeLayer'/></td></tr>
	               
	               <tr><td class="wTitle" :style="getTitleStyle()">{{tr("Media")}}</td><td colspan="3"><GSelectMSimple :options="channels" @change='getNewName' v-model="placement.media" entity='Media'/></td></tr>
	               <tr><td class="wTitle" :style="getTitleStyle()">{{tr("Capping")}}</td>
	                  <td colspan="3">
	                  <Cappings :simple="false" :packed="true" :cappingUnits="capping.cappingUnits"/>
	                  </td>
	               </tr>
	               <tr><td class="wTitle" :style="getTitleStyle()">{{tr("BillingParam")}}</td><td colspan="3"><GSelectSimple @change='getNewName' :options="billingParams" v-model="placement.billingParameterId" entity='BillingParameter'/></td></tr>
	              
	               <tr v-if="targetGroups && targetGroups.length > 0"><td class="wTitle" :style="getTitleStyle()">{{tr("TargetGroup")}}</td><td><GSelectSimple :options="targetGroups" v-model="placement.targetgroupId" entity='TargetGroup'/></td></tr>
	               
	               <tr v-if="rotations && rotations.length > 0"><td class="wTitle" :style="getTitleStyle()">{{tr("Rotation")}}</td><td colspan="3"><GSelectSimple :options="rotations" v-model="placement.rotationId" entity='Rotation'/></td></tr>
	               
	               <tr><td class="wTitle" :style="getTitleStyle()">Break types</td><td colspan="3"><GSelectMSimple :options="breakTypes" v-model="placement.breakTypes" entity='Inventory'/></td></tr>
	               <tr><td class="wTitle" :style="getTitleStyle()">Duration (sec)</td><td ><InputInt @change='getNewName' v-model='placement.duration'/></td></tr>
	               
	               	
	              
	               </table>
	               
	               <div style='position: absolute; float: bottom; width: 100%; bottom: 0;  padding-right: 15pt; padding-bottom: 8pt;'>
	                 
	                 <Button :style="getAppStyle()+'float:right;'" class='button btn-save' @click="save">{{isCopy()?'Create copy of':'Create'}} {{tr('Placement')}}</button>
	                 
	                 <Button :style="getAppStyle()+'float:right;'" class='button' title='reload all data' @click="open(popupData, productId, campaignId)"><mdicon width="20" name='reload'/></button>
	                 <Button :style="getAppStyle()+'float:right;'" class='button' @click="close">Close</button>
	                 
	                 <Button :style="getAppStyle()+'float:right; margin-right: 50pt;'" class='button btn-create' @click="newRotation">Create {{tr('Rotation')}}</button>
	                 
	               </div>
				</div>
	
		      </slot>
		      </div>
		     
		      </div>
		 </div>	
		 <GFWEOpenEditor ref='editor' @insert="open(popupData)"></GFWEOpenEditor>
	  </XModal>
	  
	
	<GFWEditorInventory :ref='getInvEditorId()' @inventory='setInventory' ></GFWEditorInventory>
	<GConfirm ref='confirm'/>
	
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { tr } from '@/translate.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import InputInt from '@/components/inputElements/InputInt';
import InputDatePick2 from '@/components/inputElements/InputDatePick3';
import Cappings from '@/components/booking/Cappings'
//import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
//import InventoryFilterEditor from '@/components/inputElements/InputInventoryFilterNew';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
import InputMoney from '@/components/inputElements/InputMoney';
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_PLACEMENT_WIZARD',
  components : {
      GConfirm, InputText, InputInt, InputMoney, GSelectSimple, GSelectMSimple, InputDatePick2, InventoryFilterEditor,
      GFWEditorInventory, Cappings
  },
  props: {
    name: {
      type: String,
      default: 'PLACEMENT_WIZARD'
    },
    heightInp: {
      type: Number,
      default: 85
    }
  },
  data () {
    return {
		width: 60,
		businessTypes: [],
		statuses: [],
		capping: [],
		currencies: {},
		currenciesFull: {},
		campaign: {},
		height: this.heightInp,
		productId: 0,
		campaignId: 0,
		targetTypes: [],
		placementTypes: [],
		timeLayers: [],
		priceTypes: [],
		myId: Math.round(Math.random()*100000000),
		billingParams: [],
		targetGroups: [],
		rotations: [],
		salesUnits: [],
		channels: [],
		breakTypes: [],
		popupData: {},
		placement: {},
		allProducts: [],
		allCampaigns: null,
		tr: tr,
		getAppStyle, 
		getFGColor
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: { 
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     getInvEditorId() {
	    	return 'INVEDIT'+this.myID; 
	 },
	 getInvEditor() {
    	if ( Array.isArray(this.$refs[this.getInvEditorId()]))
		{
			return this.$refs[this.getInvEditorId()][0];
		}
		else
		{
			return this.$refs[this.getInvEditorId()];
		}
    },
    newRotation()
    {
    	this.$refs.editor.open( 'Rotation', 0, this.createRotation)
    },
    isCopy()
    {
		return this.popupData.mode==='copy' || this.popupData.mode==='copyTo'; 
	},
	isCopyToDiffentCampaign()
    {
		return this.popupData.mode==='copyTo'; 
	},
    createRotation( rot)
    {
    	rot.productId = this.productId;
    	rot.name = this.placement.name;
    	return rot;
    },
     findCurrency()
     {
    	 
         if ( this.currenciesFull && this.currenciesFull.length > 0 )
         {
    	 	return this.currenciesFull.find(p=>p.id === this.placement.currencyId)	
         }
         return {};
      },
     save()
     {
     	this.createPlacement();
     },
     getTitleStyle()
     {
    	return "color: "+getFGColor("#228","#ccf");
     },
     setInventory(data)
     {
     	this.placement = {... data}
     },
     openInvEditor( filter, record, columnName, forceOpen)
     {
    	 
    	 this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventory);
     },
     newInv()
     {
     	this.$refs.editor.open('Inventory', 77)
     },
     insertInventory(entity, record) 
     {
       if ( record.id )
       {
        this.placement.inventoryId = record.id;
       	if ( ! this.inventorys.find( p=>p.id == record.id))
       	{
			this.inventorys.push( {id: record.id, label: record.name, visible: true});
	   	}
	   }
     },
     getCappings( cappings, withEmpty)
 	 { 
 		let that = this;
 		let list = [];
 		for ( let capi in cappings )
         {
 			let unit = cappings[ capi];
 			let map = {id: 0, cappingUnitId: unit.id, capping: unit.capping };
 			map.capping = unit.capping;
 			if ( withEmpty || map.capping)
 			{
 				list.push( map)
         	}
         }
 		return list;

 	},
     createPlacement()
	 {
	    let that = this;
	    let action = this.isCopy()?'Copy':'Create';
	    //console.log(  JSON.stringify(that.placement));
		//alert( this.campaignId+"/"+ that.placement.campaignId+ "/"+ that.popupData.line.campaignId);
	    let cappingList = that.getCappings( that.capping.cappingUnits, false);
		that.placement.campaignId = that.popupData.line.campaignId;
	    if ( this.isCopyToDiffentCampaign())
	    {
			that.popupData.line.campaignId = this.campaignId;
			that.popupData.line.productId = this.productId;
			that.placement.campaignId = this.campaignId;
		}
		let mode = 'create';
		if ( this.isCopy())
		{
			mode = 'copy';
		}
	    that.$iosConfirm( action+' '+tr('Placement')+ ' ' + that.placement.reference).then(x => { 
	       
		 	HTTP.put( opsAPI+"/createPlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+that.popupData.line.id, 
		 			  {salesRequest: that.popupData.line, placement: that.placement, cappings: cappingList, mode: mode})	
	    		 .then( response => 
	             {
	             	//alert( JSON.stringify(response.data));
	             	that.$set( that.popupData, 'line', response.data.salesRequest);
         			that.placement = response.data.placement;
         			that.$emit('placement', that.placement);
					that.close();
	             }).catch(e => {
	                    showError( that.$toast, "loading "+name, e);
	             });  

         });
	 },
	 getNewName()
	 {
		let that = this;
		that.placement.productId = that.productId; 
	    that.placement.campaignId = that.campaignId; 
	    HTTP.post( opsAPI+"/getPlacementName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+that.popupData.line.id, {salesRequest: that.popupData.line, placement: that.placement})	
	    		 .then( response => 
	             {
	             	//alert( JSON.stringify(response.data.first));
	             	that.$set( that.placement, 'name', response.data.first);
	             	that.$set( that.placement, 'breakTypes', response.data.second.breakTypes);
	             }).catch(e => {
	                    showError( that.$toast, "loading "+name, e);
	             });  

	 },
	 productHasChanged( productId )
	 {
		 let that = this;
		 if ( !productId)
		 {
			productId = that.placement.productId;
		 }
		 if (!productId)
		 {
			return;
		 }
		 HTTP.post( opsAPI+"/findCampaign4Product/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+productId)	
		 .then( response => 
         {
			 if ( response.data.rotations && response.data.rotations.length)
			 {
				that.rotations = response.data.rotations.map( p => {return {id: p.id, label: p.name, visible: true};});
			 }
			 if ( response.data.campaign && response.data.campaign.length)
			 {
				that.allCampaigns = response.data.campaign.map( p => {return {id: p.id, label: p.name, visible: true};});
			 	that.campaignId = that.allCampaigns[0].id;
			 }
			 that.productId = productId;
			 that.getNewName();
	     }).catch(e => {
                showError( that.$toast, "loading findCampaign4Product", e);
         }); 
	 },
	 open(popupData, productId, campaignId) {
		
	    this.popupData = popupData;
	    if ( this.isCopyToDiffentCampaign)
	    {
	    	this.height = 88;
	    }
	    else
	    {
			this.height = 85;
		}
		let that = this;
		let salesRequest = { ... popupData.line};
		//alert( campaignId +"/"+salesRequest.campaignId)
		if ( productId )
		{
			salesRequest.productId = productId;
		}
		if ( campaignId )
		{
			salesRequest.campaignId = campaignId;
		}
		
		HTTP.put( opsAPI+"/preparePlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.popupData.line.salesUnitId+"/"+sessionStorage.userId+"/"+this.popupData.mode+"/"+this.popupData.line.id, salesRequest)	
		 .then( response => 
         {
         	alert( JSON.stringify(response.data.placement.campaignId));
         	that.$set( that.popupData, 'line', response.data.salesRequest);
         	that.placement = response.data.placement;
			
         	that.placement.visualDisplay = "Placement wizard";
         	if ( !that.placement.inventory)
         	{
         		that.placement.inventory = { title: 'No restriction'}
         	}
         	that.capping = {};
         	that.capping.cappingSaved = [];
         	that.capping.cappingUnits = response.data.cappingUnits;
            for ( let capi in that.capping.cappingUnits )
            {
            	let unit = that.capping.cappingUnits[ capi];
            	let cap = response.data.cappings.find(p=>p.cappingUnitId===unit.id);
            	if ( cap )
            	{
            		unit.capping = cap.capping;
            		unit.mapId = cap.id;
            	}
            	that.capping.cappingSaved.push( {...unit});
            }
            if ( that.placement.price )
            {
            	that.placement.price = Math.round( that.placement.price * 100) / 100;
            }
			
         	that.campaign = response.data.campaign;
         	that.currenciesFull = response.data.currencies;
         	that.placement.billingParameterId = that.campaign.billingParameterId;
         	that.salesUnits = response.data.salesUnits.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.businessTypes = response.data.businessTypes.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.statuses = response.data.statusList.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.allProducts = response.data.products.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.currencies = response.data.currencies.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.targetTypes = response.data.targetTypes.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.targetGroups = response.data.targetGroups.map( p => {return {id: p.id, label: p.name, visible: true};});
			if ( response.data.rotations)
         		that.rotations = response.data.rotations.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.priceTypes = response.data.priceTypes.map( p => {return {id: p.id, label: p.name, visible: true};});
         	//that.inventorys = response.data.inventorys.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.channels = response.data.media.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.breakTypes = response.data.breakTypes.map( p => {return {id: p.id, label: p.shortname, visible: true};});
         	that.placementTypes = response.data.placementTypes.map( p => {return {id: p.id, label: p.shortname, visible: true};});
         	that.timeLayers = response.data.timeLayers.map( p => {return {id: p.id, label: p.name, visible: true};});
         	that.billingParams = response.data.billingParams.map( p => {return {id: p.id, label: p.name, visible: true};});
         	//that.currency = response.data.currency;
         	that.timeLayers.push({id: null, label: 'Program specific', visible: true})
			
         	if ( ! productId)
         	{
         		that.productId = that.placement.productId;
				if ( !that.allProducts.find(p=>p.id==that.productId))
				{
					that.productId = null;
				}
         	}
         	if ( ! campaignId)
         	{
         		that.campaignId = that.placement.campaignId;
         	}
			
         	that.$modal.show(that.name);
			that.$forceUpdate();
         }).catch(e => {
			    console.log(e)
                showError( that.$toast, "loading "+name, e);
         });  
		
	 	
	 	

	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     }
	 
  },
  watch: {
	productId: function() { this.productHasChanged( this.productId) }
  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}
.wTitle {
	padding-top: 4px;
	padding-left: 5px;
}

.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 100% !important;
  
  float: top;
} 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.btn-create {
	background-color: #88c !important;
    color: #fff !important;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
